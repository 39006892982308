import React from "react";
import { Link } from "gatsby";
import { Seo } from "../components/Seo";

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

const SuccessPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center">
      <h1 className="text-4xl tracking-wider lg:text-6xl">SUCCESS!</h1>
      <p className="mt-2 text-xl lg:text-2xl">
        We have just sent a confirmation to your email! Feel free to reach out
        to us, should you have any further questions. &#128515;
      </p>
      <p className="mt-2 text-xl lg:text-2xl">
        Please follow to the main page by clicking{" "}
        <Link to="/" className="text-orange-700 underline hover:text-blue-700">
          here
        </Link>
        .
      </p>
    </div>
  );
};

export default SuccessPage;
