// https://www.gatsbyjs.com/docs/how-to/adding-common-features/adding-seo-component/

import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query UseSiteMetadataQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
          author
        }
      }
      ogImg: file(relativePath: { eq: "meta/pot-social.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, layout: FIXED)
        }
      }
    }
  `);

  return {
    ...data.site.siteMetadata,
    ogImg: data.ogImg.childImageSharp.gatsbyImageData,
  };
};
