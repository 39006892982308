import React from "react";
import PropTypes from "prop-types";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { pageTitleMap, pageDescriptionMap } from "../utils/seo-utils";

export const Seo = ({ pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    author,
    ogImg,
  } = useSiteMetadata();

  const title = pageTitleMap[pathname];
  const description = pageDescriptionMap[pathname];
  const ogImage = ogImg.images.fallback.src;

  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    image: `${siteUrl}${ogImage}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="author" content={author} />
      <meta name="theme-color" content="#ed8936" />
      <meta name="og:title" content={title || defaultTitle} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={seo.url} />
      <meta name="og:image" content={seo.image} />
      <meta name="og:image:height" content="480" />
      <meta name="og:image:width" content="480" />
      <link rel="canonical" href={seo.url} />
      {children}
    </>
  );
};

Seo.defaultProps = {
  pathname: '/',
};

Seo.propTypes = {
  pathname: PropTypes.string,
  children: PropTypes.node,
};
