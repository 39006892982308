export const pageTitleMap = {
  "/green-growth-and-culture/": "Green Growth And Culture",
  "/create-your-own-job/": "Create Your Own Job",
  "/joint-effort/": "Joint Effort",
  "/let-job-find-you/": "Let Job Find You",
  "/let-job-find-you/apply/": "Apply To Let Job Find You Program",
  "/self-improvement/": "Self Improvement",
  "/shared-kitchen/": "Shared Kitchen",
  "/shine-bright/": "Shine Bright",
  "/transition-to-it/": "Transition To IT",
  "/welcome-to-sweden/": "Welcome To Sweden",
  "/welcome-to-sweden/apply/": "Apply To Welcome To Sweden Program",
  "/about-us/": "About Us",
  "/joinus/": "Join Us",
  "/success/": "Successful Submission",
  "/404/": "404 Not Found",
  "/": "",
};

export const pageDescriptionMap = {
  "/green-growth-and-culture/": "How do we address sustainability and inclusion with young people with hearing, visual, and intellectual disabilities?",
  "/create-your-own-job/": "",
  "/joint-effort/": "",
  "/let-job-find-you/": "",
  "/let-job-find-you/apply/": "",
  "/self-improvement/": "Self-improvement, individual growth, empowerment, engagement",
  "/shared-kitchen/": "",
  "/shine-bright/": "Peer-support, mentorship, supervision, and networking",
  "/transition-to-it/": "Mentorship program to help you with the transition to IT, or upgrading your current profession with IT skills",
  "/welcome-to-sweden/": "Our workshop series to help expats find a job in Sweden",
  "/welcome-to-sweden/apply/": "",
  "/about-us/": "Perform On Top is a community of ambitious like-minded people who aim to unlock full potential and excel in professional journey.",
  "/joinus/": "Welcome to join Perform On Top!",
  "/success/": "Success!",
  "/404/": "Ups! :///",
  "/": "",
};
